<template>
  <div class="personal-profile-container">
    <div class="personal-profile-body bgc_fff">
      <header class="personal-profile-header flex flex_jcbetween flex_acenter">
        <span class="fontsize_14 color_26">个人资料</span>
      </header>
      <el-row class="personal-profile-main flex ">
        <el-col class="personal-avatar">
          <el-upload
            class="personal-avatar-uploader"
            name="file"
            :headers="headers"
            :action="https + '/api/V1/upload/file'"
            accept=".bmp,.jpg,.png,.jpeg"
            :show-file-list="false"
            :multiple="false"
            :on-change="uploadChange"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload">
            <img v-if="form.avatar" :src="form.avatar" class="avatar" loading="lazy">
            <i v-else class="icon-default_avatar" style="font-size:120px;"></i>
          </el-upload>
        </el-col>
        <el-col class="personal-form">
          <el-form ref="form" :model="form" label-width="80px" label-position="top" @submit.native.prevent>
            <el-form-item label="姓名">
              <el-input v-model="form.username" placeholder="请输入姓名" placeholder-class="" @blur="inputBlur('username')"></el-input>
              <div class="input-error-msg" v-if="errMsg">
                <i class="icon-warning fontsize_12"></i><span class="padding_left8 color_E74362 fontsize_12">{{errMsg}}</span>
              </div>
            </el-form-item>
            <el-form-item label="生日">
              <el-col>
                <el-date-picker type="date" placeholder="请选择生日日期" 
                  format="yyyy 年 MM 月 dd 日"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                  v-model="form.birthday" prefix-icon="icon-calendar"
                  :clearable="false" style="width: 100%;"
                ></el-date-picker>
              </el-col>
            </el-form-item>
            <el-form-item label="职业">
              <el-input v-model="form.profession" placeholder="请输入职业"></el-input>
            </el-form-item>
            <el-form-item label="所在城市">
              <!-- <el-select v-model="form.city" placeholder="请输入所在城市" class="width_100p">
                <el-option label="上海" value="shanghai"></el-option>
                <el-option label="北京" value="beijing"></el-option>
              </el-select> -->
              <el-input v-model="form.city" placeholder="请输入所在城市" placeholder-class=""></el-input>
            </el-form-item>
            <el-form-item label="个人简介">
              <el-input type="textarea" v-model="form.personal_profile" placeholder="请输入个人简介" :autosize="{minRows: 5, maxRows: 9}"></el-input>
            </el-form-item>
            <el-form-item class="submit-button">
              <customButton @click="onSubmit" color="#E74362" :width="88" :height="40" :roundPX="24" :fontSize="14">
                <span class="fontsize_12 color_fff prel">更新资料</span>
              </customButton>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/libs/utils/auth';
import customButton from "@/renderer/components/common/button.vue";
import { mapActions } from 'vuex';
export default {
  components: {customButton},
  data() {
    return {
      https:process.env.VUE_APP_API,
      token:null,
      errMsg: "",
      form: {
        username: "",
        city: "",
        birthday: "",
        personal_profile: "",
        profession:"",
        avatar:""
      },
      pickerOptions: {
        disabledDate(time) { //限制日期选择今天之前
          return time.getTime() > Date.now();
        },
      },
    };
  },
  computed: {
    headers() {
      return {
        Authorization : 'Bearer ' + this.token
      }
    },
  },
  async created() {
    const token = await getToken();
    this.token = token;

    this.getInfo();
  },
  methods: {
    ...mapActions(["GetUserDetail", "UpdateUserInfo"]),
    getInfo() {
      this.GetUserDetail().then(res => {
        if(res.errorcode == 0) {
          this.form = res.data;
        }
      }).catch();
    },
    inputBlur(propName) {
      if(propName === "username" && this.form.username && this.form.username.trim()) {
        this.errMsg = "";
      }
    },
    onSubmit() {
      let { username } = this.form;
      if(!username || !username.trim()) {
        this.errMsg = "用户名不能为空";
        return;
      }
      this.UpdateUserInfo(this.form).then(res => {
        if(res.errorcode == 0) {
          this.form = res.data;
          this.$message.success(res.msg);
        }else {
          this.$message.warning(res.msg);
        }
      });
    },
    uploadChange(file,fileList) {
      // 文件选择改变
      this.form.avatar = URL.createObjectURL(file.raw);
    },
    handleAvatarSuccess(res, file) {
      // this.form.avatar = URL.createObjectURL(file.raw);
      if(res.errorcode == 0) {
        this.form.avatar = res.data && res.data.cover;
        this.onSubmit();
      }
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isJPG) {
      //   this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      // if (!isLt2M) {
      //   this.$message.error('上传头像图片大小不能超过 2MB!');
      // }
      // return isJPG && isLt2M;
      return true;
    }
  },
}
</script>

<style lang="less" scoped>
  .personal-profile-container {
    box-sizing: border-box;
    padding: 16px 0;
    height: 100%;
  }
  .personal-profile-body {
    box-sizing: border-box;
    margin: 0 auto;
    border-radius: 4px;
    padding: 0 24px;
    width: 58%;
    height: 100%;
    max-width: 836px;
    max-height: 800px;
  }
  .personal-profile-header {
    height: 68px;
    border-bottom: 1px solid #F0F0F0;
  }
  .personal-profile-main {
    height: calc(100% - 69px);
    overflow: hidden auto;
    box-sizing: border-box;
    padding: 20px 8px 24px;
  }
  .personal-avatar {
    position: relative;
    flex-shrink: 0;
    padding-right: 32px;
    width: 152px;
    .personal-avatar-uploader {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 120px;
      /deep/.el-upload {
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      /deep/.avatar {
        border-radius: 50%;
        width: 120px;
        height: 120px;
        object-fit: cover;
      }
    }
  }
  .submit-button {
    margin-top: 32px;
    button {
      position: relative;
      &:hover {
        &::before {
          position: absolute;
          display: block;
          content: "";
          z-index: 0;
          border-radius: 24px;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
  // 表单样式重置
  .personal-form {
    /deep/input::input-placeholder {
      color:  #A1A1A1 !important;
    }

    /deep/input::-webkit-input-placeholder,
    /deep/textarea::-webkit-input-placeholder {
      color:  #A1A1A1 !important;
    }

    /deep/input:-moz-placeholder,
    /deep/textarea:-moz-placeholder {
      color:  #A1A1A1 !important;
    }

    /deep/input::-moz-placeholder,
    /deep/textarea::-moz-placeholder {
      color:  #A1A1A1 !important;
    }

    /deep/input:-ms-input-placeholder,
    /deep/textarea:-ms-input-placeholder {
      color: #A1A1A1 !important;
    }
    /deep/.el-form-item {
      margin-bottom: 0;
      padding-bottom: 24px;
    }
    /deep/.el-form--label-top .el-form-item__label {
      padding-bottom: 8px;
      font-size: 14px;
      color: #222222;
      line-height: 20px;
    }
    /deep/.el-input__inner {
      padding: 0 16px;
      border: 1px solid #D9D9D9;
      border-radius: 4px;
      &::placeholder {
        color: #BFBFBF !important;
      }
    }
    /deep/.el-textarea__inner {
      border: 1px solid #D4D4D4;
      border-radius: 4px;
      padding: 8px 16px;
      line-height: 20px;
      // min-height: 120px;
      &:focus {
        border-color: #E74362;
      }
      &::placeholder {
        color: #BFBFBF !important;
      }
    }
    /deep/.el-select .el-input.is-focus .el-input__inner {
      border-color:#D4D4D4;
    }
    /deep/.el-input__prefix {
      // display: none;
      left: auto;
      right: 16px;
      &:hover {
        color: #E74362;
      }
    }
    /deep/.el-input {
      height: 36px;
      line-height: 36px;
      font-size: 14px;
      .el-input__inner:focus {
        border-color: #E74362;
      }
    }
    // 日期
    /deep/.el-date-editor {
      background: #FAFAFA;
    }
    // 日期插件后
    .el-date-editor.el-input, .el-date-editor.el-input__inner {
      position: relative;
      // &::after {
      //   position: absolute;
      //   top: 0;
      //   right: 16px;
      //   z-index: 3;
      //   display: block;
      //   font-family: 'icomoon';
      //   content: "\e9e1";
      //   width: 10px;
      //   height: 10px;
      //   // pointer-events: none;
      //   // cursor: pointer;
      //   &:hover {
      //     color: #E74362;
      //   }
      // }
    }
  }
</style>